<template>
  <div class="static-page landing-static"  :class="{ 'rtl': locale === 'ar' }">
    <AppHeader appName="main" :locale="locale"/>
    <main>
      <section class="faq-page">
        <div class="container-medium">
          <h1 class="main-title">
            <span >{{$t('faq_search_questions')}}</span>
            <div class="faq-page__return" @click="goToMainFaq"><img src="./../assets/images/return.svg" alt="return"></div>
          </h1>
          <BaseInput
            ref="input"
            :placeholder="$t('faq_search_your_questions')"
            inputStyle="big"
            class="faq-page__input"
            v-model="searchValue"/>
          <h3  v-if="filtredCategoryCodes.length" class="faq-page__res-title">{{$t('faq_search_results')}}</h3>
          <h3  v-else class="faq-page__res-title" >{{$t('faq_all_no_found')}}</h3>
          <div class="faq-page__chips" v-if="filtredCategoryCodes.length">
            <div class="faq-page__chips-item" v-for="(filter, index) of filtredFilters" :key="index">
              <input type="radio" :id="filter.filter" :value="filter.filter" v-model="currentFilter"/>
              <label :for="filter.filter">
                {{filter.name}}
              </label>
            </div>
          </div>
          <template v-if="!error && currentFilter==='all' && filtredCategoryCodes.length">
            <LandingFaqs
              faqHeaderKey="question"
              faqContentKey="answer"
              v-for="(code, index) of filtredCategoryCodes"
              :key="index"
              :title="filtredAllFaqsMap[code].title"
              :faqList="filtredAllFaqsMap[code].list"/>
          </template>
          <LandingFaqs
            v-if="!error && !!filtredFaqs.length && currentFilter !=='all'"
            faqHeaderKey="question"
            faqContentKey="answer"
            :title="currentCategoryTitle"
            :disableTitle="categorySelected || !currentCategoryTitle"
            :faqList="filtredFaqs"/>
        </div>
      </section>
    </main>
    <AppFooter :locale="locale"/>
  </div>
</template>

<script>
import { AppHeader, AppFooter, BaseInput, LandingFaqs } from 'qic-components'
import { mapActions } from 'vuex'

export default {
  name: 'FaqPage',
  components: {
    AppHeader,
    AppFooter,
    BaseInput,
    LandingFaqs
  },
  props: {
    mode: { type: String, default: 'main' },
    category: { type: String, default: 'all' }
  },
  emits: [
    'onCategoryClick',
    'onSearchClick',
    'onBackButton'
  ],
  data () {
    return {
      error: false,
      placeholder: ' ',
      loadingFaqs: false,
      faqs: null,
      currentFilter: 'all',
      categoryCodesOrder: [],
      categorySelected: false,
      searchValue: '',
      filters: [
        { name: this.$t('faq_filter_name_all'), filter: 'all' },
        { name: this.$t('faq_filter_name_car'), filter: 'car' },
        { name: this.$t('faq_filter_name_moto'), filter: 'moto' },
        { name: this.$t('faq_filter_name_home'), filter: 'home' },
        { name: this.$t('faq_filter_name_travel'), filter: 'travel' },
        { name: this.$t('faq_filter_name_personal'), filter: 'pab' },
        { name: this.$t('faq_filter_name_boat'), filter: 'boat' },
        { name: this.$t('faq_filter_name_claims'), filter: 'claims' }
      ]
    }
  },
  computed: {
    locale () {
      return this.$i18n.locale
    },
    isArabic () {
      return this.locale === 'ar'
    },
    filtredFilters () {
      return [{ name: this.$t('faq_filter_name_all'), filter: 'all' },
        ...this.filters.filter((f) => {
          return this.filtredAllFaqsMap[f.filter]?.list?.length
        })]
    },
    filtredCategoryCodes () {
      return this.categoryCodesOrder.filter(c => {
        return this.filtredAllFaqsMap[c]
      })
    },
    filtredAllFaqsMap () {
      if (!this.faqs) return {}
      return Object.fromEntries(Object.entries(this.faqs)
        .map(([key, faqs]) => {
          return [
            key,
            {
              title: faqs.title,
              list: faqs.list.filter(f => {
                return f.question.toLowerCase().includes(this.searchValue.toLowerCase())
              })
            }]
        })
        .filter(([_, faqs]) => {
          return faqs?.list.length
        })
      )
    },
    filtredFaqs () {
      if (!this.faqs) return []
      if (this.currentFilter === 'all') {
        return []
      } else {
        if (!this.searchValue) return this.faqs[this.currentFilter]?.list || []
        return this.filtredAllFaqsMap[this.currentFilter]?.list || []
      }
    },
    currentCategoryTitle () {
      if (!this.faqs) return ''
      return this.faqs[this.currentFilter] ? this.faqs[this.currentFilter].title : ''
    }
  },
  methods: {
    ...mapActions([
      'getFaqList'
    ]),
    selectCategory (category) {
      this.$emit('onCategoryClick')
      this.currentFilter = category
      this.categorySelected = true
    },
    onBackButton () {
      this.$emit('onBackButton')
      this.categorySelected = false
      this.searchValue = ''
      this.currentFilter = 'general'
    },
    goToMainFaq () {
      this.$router.replace({ name: 'MainFaqPage' })
    }
  },
  mounted () {
    this.loadingFaqs = true
    this.$refs.input.$refs.input.focus()
    this.getFaqList(this.locale)
      .then((faqsInfo) => {
        if (!faqsInfo || !(typeof faqsInfo.faqs === 'object')) {
          this.error = true
          return
        }

        this.faqs = faqsInfo.faqs
        this.categoryCodesOrder = faqsInfo.categoryCodes
      })
      .finally(() => {
        this.loadingFaqs = false
      })
  },
  watch: {
    filtredFilters (newFiltred) {
      let changeFilter = 0
      for (const f of newFiltred) {
        if (f.filter === this.currentFilter) {
          changeFilter = changeFilter + 1
        }
      }
      if (!changeFilter) this.currentFilter = newFiltred[0]?.filter
    }

  },
  metaInfo: {
    meta: [
      { name: 'theme-color', content: '#ffffff' },
      { name: 'msapplication-navbutton-color', content: '#ffffff' },
      { name: 'apple-mobile-web-app-status-bar-style', content: '#ffffff' }
    ]
  }
}
</script>
