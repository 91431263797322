<template>
  <div class="static-page landing-static"  :class="{ 'rtl': locale === 'ar' }">
    <AppHeader appName="main" :locale="locale"/>
    <main>
      <section class="faq-page">
        <div class="container-medium">
          <div  class="faq-page__chips">
            <div class="faq-page__chips-item" v-for="(filter, index) of filters" :key="index">
              <input type="radio" :id="filter.filter" :value="filter.filter" v-model="currentFilter"/>
              <label :for="filter.filter">
                {{filter.name}}
              </label>
            </div>
          </div>
          <h3 class="main-title">
            <span>{{ currentCategoryTitle }}</span>
            <div class="faq-page__return" @click="goToMainFaq"><img src="./../assets/images/return.svg" alt="return"></div>
          </h3>
          <template  v-if="!error && faqs && currentFilter==='all' && categoryCodesOrder.length">
            <LandingFaqs
              faqHeaderKey="question"
              faqContentKey="answer"
              v-for="(code, index) of categoryCodesOrder"
              :key="index"
              :title="faqs[code].title"
              :faqList="faqs[code].list"/>
          </template>
          <LandingFaqs
            v-if="!error && !!filtredFaqs.length && currentFilter !=='all'"
            faqHeaderKey="question"
            faqContentKey="answer"
            :title="currentCategoryTitle"
            :disableTitle="!!currentCategoryTitle"
            :faqList="filtredFaqs"/>
        </div>
      </section>
    </main>
    <AppFooter :locale="locale"/>
  </div>
</template>

<script>
import { AppHeader, AppFooter, LandingFaqs } from 'qic-components'
import { mapActions } from 'vuex'

export default {
  name: 'FaqCategory',
  components: {
    AppHeader,
    AppFooter,
    LandingFaqs
  },
  props: {
    category: String
  },
  data () {
    return {
      error: false,
      placeholder: ' ',
      loadingFaqs: false,
      faqs: null,
      currentFilter: 'all',
      categoryCodesOrder: [],
      filters: [
        { name: this.$t('faq_filter_name_all'), filter: 'all' },
        { name: this.$t('faq_filter_name_car'), filter: 'car' },
        { name: this.$t('faq_filter_name_moto'), filter: 'moto' },
        { name: this.$t('faq_filter_name_home'), filter: 'home' },
        { name: this.$t('faq_filter_name_travel'), filter: 'travel' },
        { name: this.$t('faq_filter_name_personal'), filter: 'pab' },
        { name: this.$t('faq_filter_name_boat'), filter: 'boat' },
        { name: this.$t('faq_filter_name_claims'), filter: 'claims' }
      ]
    }
  },
  computed: {
    locale () {
      return this.$i18n.locale
    },
    isArabic () {
      return this.locale === 'ar'
    },
    filtredFaqs () {
      if (!this.faqs) return []
      if (this.currentFilter === 'all') {
        return []
      } else {
        return this.faqs[this.currentFilter].list
      }
    },
    currentCategoryTitle () {
      if (!this.faqs) return ''
      return this.faqs[this.currentFilter] ? this.faqs[this.currentFilter].title : this.$t('faq_all_categories')
    }
  },
  methods: {
    ...mapActions([
      'getFaqList'
    ]),
    selectCategory (category) {
      this.currentFilter = category
      this.categorySelected = true
    },
    goToMainFaq () {
      this.$router.replace({ name: 'MainFaqPage' })
    }
  },
  mounted () {
    this.loadingFaqs = true
    this.getFaqList(this.locale)
      .then((faqsInfo) => {
        if (!faqsInfo || !(typeof faqsInfo.faqs === 'object')) {
          this.error = true
          return
        }

        if (this.category) this.currentFilter = this.category
        this.faqs = faqsInfo.faqs
        this.categoryCodesOrder = faqsInfo.categoryCodes
      })
      .finally(() => {
        this.loadingFaqs = false
      })
  },
  metaInfo: {
    meta: [
      { name: 'theme-color', content: '#ffffff' },
      { name: 'msapplication-navbutton-color', content: '#ffffff' },
      { name: 'apple-mobile-web-app-status-bar-style', content: '#ffffff' }
    ]
  }
}
</script>
